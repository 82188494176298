import { RouteComponentProps } from '@reach/router';
import React from 'react';
import api from '../../lib/Api';
import ReleaseItem, { ReleaseItemGroup, ReleaseItemState } from '../../lib/models/ReleaseItem';
import './ReleasesOverview.scss';
import { environments } from '../../lib/Known';

interface Props extends RouteComponentProps { }
interface State {
    releaseItemState?: ReleaseItemState;
    viewMore: Map<string, boolean>;
}

export default class ReleasesOverview extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = { releaseItemState: undefined, viewMore: new Map() };
        api.releaseItems.list().then((releaseItemState) => this.setState({ releaseItemState }));
    }

    public render() {
        if (!this.state.releaseItemState || !Object.keys(this.state.releaseItemState).length) {
            return <div className='section container'>no releases found</div>;
        }

        return <div className='section container'>
            {Object.keys(this.state.releaseItemState).sort().map((group) => this.renderItemGroup(group, this.state.releaseItemState![group]))}
        </div>;
    }

    private renderItemGroup(name: string, itemGroup: ReleaseItemGroup) {
        return <div className='releaseItemGroup' key={name}>
            <h4>{name}</h4>

            <div className='envcontainer'>
                {Array.from(environments.keys()).map(env =>
                    <div className='env' key={env}>
                        <h5>{environments.get(env)}</h5>

                        <div className='itemList'>{itemGroup[env]
                            ? this.renderItems(name, env, itemGroup[env])
                            : 'None'}</div>
                    </div>
                )}
            </div>
        </div>
    }

    private renderItems(name: string, env: string, items: ReleaseItem[]) {
        const vmKey = `${name}_${env}`;
        const sorted = items.sort(ReleasesOverview.sortByDateDesc);
        const showingMore = this.state.viewMore.has(vmKey) && this.state.viewMore.get(vmKey);
        const wanted = showingMore ? sorted.length : 3;
        const showing = sorted.slice(0, wanted > sorted.length ? sorted.length : wanted);
        const totalAvailable = sorted.length;

        return <>
            {showing.map((item) => {
                const s3URL = item.s3Location ? ReleasesOverview.s3URL(item) : undefined;

                return <div className='releaseItem' key={item.id}>
                    <div className='data'>
                        <div><a href={ReleasesOverview.pipelineURL(item)}>{item.version}</a></div>
                        {s3URL
                            ? <div className='right'><a href={ReleasesOverview.s3URL(item)}>S3</a></div>
                            : <></>}
                    </div>
                    <div className='data smaller'>
                        <div>{new Intl.DateTimeFormat('en-US', { year: '2-digit', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric' }).format(Date.parse(item.deployedAt))}</div>
                        <div className='right'>{item.versionMatch}</div>
                    </div>
                </div>;
            })}

            {!showingMore && totalAvailable > showing.length
                ? <button onClick={() => {
                    const viewMore = new Map(this.state.viewMore);
                    viewMore.set(vmKey, true);
                    this.setState({ viewMore });
                }}>{totalAvailable - showing.length} more</button>
                : <></>}
        </>;
    }

    private static pipelineURL(item: ReleaseItem) {
        return `https://us-west-1.console.aws.amazon.com/codesuite/codepipeline/pipelines/${item.name}/executions/${item.pipelineExecutionId}/visualization?region=us-west-1`;
    }

    private static s3URL(item: ReleaseItem) {
        try {
            const url = new URL(item.s3Location);

            return `https://us-west-1.console.aws.amazon.com/s3/object/${url.host}?region=us-west-1&bucketType=general&prefix=${url.pathname.replace(/^[/]/, '')}`;
        }
        catch {
            return undefined;
        }
    }

    private static sortByDateDesc(a: ReleaseItem, b: ReleaseItem) {
        const aD = Date.parse(a.deployedAt);
        const bD = Date.parse(b.deployedAt);

        return (aD < bD)
            ? 1
            : (aD > bD)
                ? -1
                : 0;
    }
}